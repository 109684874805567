// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL:'cca.edu.bo',
  VUE_APP_PATH_IMAGES:'https://cca.edu.bo/webdata/portadas/',
  VUE_APP_GOOLGE_MAPS_API_KEY:'AIzaSyCOAO9oWWZN1fOVHBaWP1vHAnTfU4zRV4Q',
  VUE_APP_MOODLE_TARIJA:'https://moodle.cca.edu.bo/',
  VUE_APP_MOODLE_SUCRE:'https://moodlesucre.cca.edu.bo/',
  
  VUE_APP_ID_CATEGORIA_FOR_LIBRES:103,
  VUE_APP_ID_CATEGORIA_FOR_CARRERA:101,
  VUE_APP_ID_CATEGORIA_FOR_FCONTINUA:104,
  VUE_APP_ID_CATEGORIA_FOR_CAPACITACION:102,
  
  VUE_APP_LINK_ESAM:'https://esam.edu.bo/',
  VUE_APP_LINK_CCA:'https://cca.edu.bo/',
  VUE_APP_LINK_CIBERKIDS:'https://ciberkids.cca.edu.bo/',
  VUE_APP_LINK_CYBERCORP:'https://cybercorp.com.bo/',
  VUE_APP_TITLE_LARGE:'Corporación Cibernética Americana',
  VUE_APP_TITLE_SHORT:'C.C.A.',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
