import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-mas-info-curso',
  templateUrl: './dialog-mas-info-curso.component.html',
  styleUrls: ['./dialog-mas-info-curso.component.scss']
})
export class DialogMasInfoCursoComponent implements OnInit {

  curso;
  constructor(
    public dialogRef: MatDialogRef<DialogMasInfoCursoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.curso=data.curso;
    console.log(this.curso)
  }

  ngOnInit(): void {
  }

}
