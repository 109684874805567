<div id="div_card_curso">
    <div id="card_curso_div_img">
        <img [src]="'assets/images/'+curso.img_card" alt="" id="card_curso_img">
    </div>
    <div id="card_curso_div_info" >
        <div id="nombre_curso">
            {{curso.nombre_curso}}
        </div>
        <div id="barra" class="mb-1"></div>
        <button id="btn_mas_info_curso" matRipple (click)="openDialogBuscarDocente()">
            Información
        </button>
    </div> 
</div>