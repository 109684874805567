import { Router } from '@angular/router';
import { AuthService } from './../@core/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-personas-talleres-gratuitos',
  templateUrl: './lista-personas-talleres-gratuitos.component.html',
  styleUrls: ['./lista-personas-talleres-gratuitos.component.scss']
})
export class ListaPersonasTalleresGratuitosComponent implements OnInit {

  constructor(
    private authService:AuthService,
    private router:Router
  ) { }

  ngOnInit(): void {
  }

  loggout(){
    this.authService.logout();
  }

}
