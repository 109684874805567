<br><br><br>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
    <div fxFlex="30rem">
        <mat-card>
            <h2>Autenticación</h2>
            <mat-divider>
            </mat-divider>
            <div class="pt-2 ">
                <mat-form-field appearance="fill" fxFlex>
                    <mat-label>Ingrese la contraseña</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="pass_input">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="0px">
                <button mat-raised-button color="primary" (click)="login()">Ingresar</button>
            </div>
        </mat-card>
    </div>
</div>
