
<!-- <mat-dialog-content > -->

        <div  id="div_dialog_curso" fxFlex="35rem" fxFlex.xs="19rem"  class="custom_container">
            <div id="titulo_sombra">
                CONTENIDO
            </div>
            <div id="titulo">
                CONTENIDO
            </div>
            <img [src]="'assets/images/'+curso.img_card" alt="" id="img_1">
            <div id="contenido_curso">
                
                <div id="div_padre_modulos">
                    <img src="assets/images/002-exam.png" id="img_fondo_modulos" alt="">
                    <div id="lista_modulos">
                        <div class="nombre_modulo" *ngFor="let modulo of this.curso.modulos">
                            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <span>- </span>
                            <div>{{modulo.nombre_modulo}}    </div>
                            </div>
                            
                        </div>
                      
                        
                    </div>
                    
                </div>
    
            </div>
            <p id="extra_info_curso" class="mb-0">
                Modalidad: <span class="mr-1 yellow">Online</span>
                Duración: <span class="mr-1 yellow">4 meses</span>
                Inversión mensual: <span class="mr-1 yellow">225 Bs</span> 
            </p>
        </div>

    
<!-- </mat-dialog-content> -->


