import gql from 'graphql-tag';

export const insertPersonaTallerGratuito = gql`
  mutation insertPersonaTallerGratuito(
    $persona_taller_gratuitoData: InsertPersonaTallerGratuitoInput!
  ) {
    insertPersonaTallerGratuito(
      persona_taller_gratuitoData: $persona_taller_gratuitoData
    ) {
      id
      nombre
      celular
      correo
      estado
      created_at
      updated_at
    }
  }
`;

export const updatePersonaTallerGratuito = gql`
  mutation updatePersonaTallerGratuito(
    $persona_taller_gratuitoData: UpdatePersonaTallerGratuitoInput!
  ) {
    updatePersonaTallerGratuito(
      persona_taller_gratuitoData: $persona_taller_gratuitoData
    ) {
      id
      nombre
      celular
      correo
      estado
      created_at
      updated_at
    }
  }
`;
