import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { insertPersonaTallerGratuito, updatePersonaTallerGratuito } from '../operations/personas_talleres_gratuitos.mutations';
import { getPersonasTalleresGratuitos } from '../operations/personas_talleres_gratuitos.queries';

@Injectable({
  providedIn: 'root'
})
export class PersonasTalleresGratuitosService {

  constructor(
    private apollo:Apollo
  ) { }

  getPersonasTalleresGratuitos(){
    return this.apollo.query({
      query:getPersonasTalleresGratuitos,
      fetchPolicy:'network-only',
    })
  }

  insertPersonaTallerGratuito(persona_taller_gratuitoData){
    return this.apollo.mutate({
      mutation:insertPersonaTallerGratuito,
      variables:{
        persona_taller_gratuitoData:persona_taller_gratuitoData
      }
    })
  }

  updatePersonaTallerGratuito(persona_taller_gratuitoData){
    return this.apollo.mutate({
      mutation:updatePersonaTallerGratuito,
      variables:{
        persona_taller_gratuitoData:persona_taller_gratuitoData
      }
    })
  }

}
