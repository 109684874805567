<mat-toolbar id="toolbar" >


    <img src="assets/logos/logo2.png" alt="" id="logo-toolbar">
    <span class="c-white">CCA</span>
    <span class="example-spacer"></span>
    <div fxShow.xs="false" fxShow="true">
        <button mat-button class="c-white" [matMenuTriggerFor]="plataformasMenu">PLATAFORMAS MOODLE</button>
        <mat-menu #plataformasMenu="matMenu" xPosition="before">
            <a href="https://moodlesucre.cca.edu.bo/" target="_blank" mat-menu-item>CCA Sucre</a>
            <a href="https://moodle.cca.edu.bo/" target="_blank" mat-menu-item>CCA Tarija</a>
        </mat-menu>
        <button mat-button class="c-white" (click)="changeFocus('#div_carreras')" >CARRERAS</button>
        <button mat-button class="c-white" (click)="changeFocus('#div_cursos')" >CURSOS</button>
        <button mat-button class="c-white" (click)="changeFocus('#div_form_dudas')" >CONTACTO</button>
    </div>
    <button fxShow.xs="true" fxShow="false" mat-icon-button class="example-icon"
        aria-label="Example icon-button with menu icon"> 
        <mat-icon class="c-white" [matMenuTriggerFor]="beforeMenu">menu</mat-icon>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <a mat-menu-item href="https://moodle.cca.edu.bo/" target="_blank" >Moodle CCA Tarija</a>
            <a mat-menu-item href="https://moodlesucre.cca.edu.bo/" target="_blank" >Moodle CCA Sucre</a>
            <mat-divider>
            </mat-divider>
            <button mat-menu-item (click)="changeFocus('#div_carreras')" >CARRERAS</button>
            <button mat-menu-item (click)="changeFocus('#div_cursos')" >CURSOS</button> 
            <button mat-menu-item (click)="changeFocus('#div_form_dudas')" >CONTACTO</button>
        </mat-menu>
    </button>

    <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button> -->
</mat-toolbar>
<div>
    <img src="assets/images/2banner.png" alt="" id="banner">
</div>
<div id="div_talleres" #1>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
        <div id="div_card_form_talleres" fxFlex="430px" fxFlex.xs="100%">
            <img src="assets/images/online-course.png" alt="" id="img_backgroud_talleres">
            <div id="div_content_form">
                <div id="form_talleres_titulo">Accede a talleres <br> GRATUITOS</div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div id="form_talleres_mes">
                        mes de junio
                    </div>
                </div>
                <form novalidate (ngSubmit)="onSubmit()" #fform="ngForm" #formDir="ngForm" [formGroup]="personaTallerFormGroup">
                <div id="div_form">
                    <div class="label_input" >
                        Nombre y apellidos
                    </div>
                    <input type="text" class="input_form " formControlName="nombre" required >
                    <div class="div_error" >
                        <span class="span_error" *ngIf="formErrors.nombre">{{formErrors.nombre}}</span>
                        <span class="span_error" *ngIf="formDir.submitted && this.personaTallerFormGroup.get('nombre').invalid && !formErrors.nombre">Este campo es requerido</span>
                    </div>
                    <div class="label_input">
                        Celular
                    </div>
                    <input type="tel" class="input_form " formControlName="celular" required>
                    <div class="div_error" >
                        <span class="span_error" *ngIf="formErrors.celular">{{formErrors.celular}}</span>
                        <span class="span_error" *ngIf="formDir.submitted && this.personaTallerFormGroup.get('celular').invalid && !formErrors.celular">Este campo es requerido</span>
                    </div>
                    <div class="label_input">
                        Correo
                    </div>
                    <input type="email" class="input_form " formControlName="correo" required>
                    <div class="div_error" >
                        <span class="span_error" *ngIf="formErrors.correo">{{formErrors.correo}}</span>
                        <span class="span_error" *ngIf="formDir.submitted && this.personaTallerFormGroup.get('correo').invalid && !formErrors.correo">Este campo es requerido</span>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px" >
                    <button type="submit" id="btn_talleres" matRipple >
                        Enviar
                    </button>
                </div>
            </form>
            </div>
        </div>
    </div>
</div>
<div id="div_numeros">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px" class="pb-3">
        <div id="div_contenedor_numeros" fxFlex="40rem" fxFlex.xs="100%">
            <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="0px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx"  fxFlex="33%">
                    <div class="numeros_nro">+20</div>
                    <div class="numeros_desc">
                        Años formando profesionales
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx" fxFlex="33%">
                    <div class="numeros_nro">+65k</div>
                    <div class="numeros_desc">
                      Estudiantes graduados
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx" fxFlex="33%">
                    <div class="numeros_nro">+140</div>
                    <div class="numeros_desc">
                        Contamos con docentes altamente calificados
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div id="div_cursos">
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
        <img src="assets/images/carreras.png" alt="" width="60px" class="mr-1">
        <div id="div_cursos_title">Cursos de capacitación</div>

    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="0px" class="mt-3">
        
        <app-card-curso *ngFor="let curso of cursos; let i=index" [curso]="curso">

        </app-card-curso>
    </div>
</div>

<div id="div_carreras">
    <div fxLayout="row warap" fxLayoutAlign="center center" fxLayoutGap="0px" class="mb-2">
        <img src="assets/images/carreras.png" alt="" width="60px" class="mr-1">
        <div id="div_cursos_title">Carreras técnico superior</div>

    </div>
    <div id="div-content-cards-carreras">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px" class="pt-3 pb-3" fxFlex="1000px"
                fxFlex.xs="100%">
                <img src="assets/images-carreras/secretariado.png" class="div_carrera" alt="">
                <img src="assets/images-carreras/adm.png" class="div_carrera" alt="">
                <img src="assets/images-carreras/sistemas.png" class="div_carrera" alt="">
                <img src="assets/images-carreras/mkt.png" class="div_carrera" alt="">
                <img src="assets/images-carreras/contaduria.png" class="div_carrera" alt="">
                <img src="assets/images-carreras/disenio.png" class="div_carrera" alt="">

            </div>
        </div>




    </div>
</div>
<div id="div_form_dudas">
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
        <div id="div_card_form_dudas" fxFlex="40rem" fxFlex.xs="100%">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
                <img src="assets/images/telefono.png" alt="" fxFlex="20rem" fxFlex.xs="10rem" id="img_dudas">
                <div id="dudas_div" fxFlex>
                    <div id="txt_tiene_dudas">
                        ¿Tiene dudas?
                    </div>
                    <div id="div_comunicate">
                        comunicate con un asesor
                    </div>
                    
                        <!-- <input type="tel" id="input_dudas" placeholder="Celular"> -->
                        <button mat-button id="btn_whatsapp" class="mt-2" (click)="enviarMensajeWhatsapp()"> <img src="assets/images/img_whatsapp.png" alt="" width="40px"
                            > Enviar mensaje</button>
                
                    
                </div>
            </div>
        </div>
    </div>
</div>