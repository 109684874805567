import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private p='ecv2234wsb945c4cxvberz//a48ssdw/.';
  private val_logged='1';
  constructor(private router:Router) { }

  getp(){
    return this.p;
  }
  getval_logged(){
    return this.val_logged;
  }

  logout(){
    localStorage.clear();
    this.router.navigateByUrl('/home')
  }

  isLogged():boolean{
    let l_p=localStorage.getItem('p');
    let l_l=localStorage.getItem('l');
    if(this.p==l_p && this.val_logged==l_l ){
      return true;
    }
    else{
      return false;
    }
  }

  
}
