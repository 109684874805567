import { AuthService } from './../@core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  pass='srT23est_';
 
  mensaje='';
  pass_input='';

  constructor(
    private authService:AuthService,
    private router:Router
  ) { }


  ngOnInit(): void {
  }
  login(){
    this.mensaje='';
    if(this.pass_input==this.pass){
      localStorage.setItem('p',this.authService.getp());
      localStorage.setItem('l',this.authService.getval_logged());
      this.router.navigateByUrl('/lista-personas-talleres-gratuitos');  
    }
    else{
      this.mensaje='La contraseña es incorrecta';   
    }
  }

}
