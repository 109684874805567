import gql from 'graphql-tag';

export const getPersonasTalleresGratuitos = gql`
  query getPersonasTalleresGratuitos {
    getPersonasTalleresGratuitos {
      id
      nombre
      celular
      correo
      estado
      created_at
      updated_at
    }
  }
`;
