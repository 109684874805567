import { DialogMasInfoCursoComponent } from './../dialog-mas-info-curso/dialog-mas-info-curso.component';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-card-curso',
  templateUrl: './card-curso.component.html',
  styleUrls: ['./card-curso.component.scss']
})
export class CardCursoComponent implements OnInit {

  @Input() curso: any;

  constructor(
    private matDialog:MatDialog
  ) { }
 
  ngOnInit(): void {
  }

  openDialogBuscarDocente() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data={curso:this.curso}
    dialogConfig.panelClass='custom_container';
    let dialogRef = this.matDialog.open(
      DialogMasInfoCursoComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result.docente);
      }
    });
  }
}
