import { PersonasTalleresGratuitosService } from './../@core/services/personas-talleres-gratuitos.service';
import { PersonaTallerGratuito } from './../@core/interfaces/persona_taller_gratuito.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  cursos = [
    {
      nombre_curso: 'Tec. operador en manejo de paquetes',
      img_card: 'img_paquetes.png',
      modulos: [
        {
          nombre_modulo: 'Excel',
        },
        {
          nombre_modulo: 'Word',
        },
        {
          nombre_modulo: 'Windows',
        },
        {
          nombre_modulo: 'Access',
        },
        {
          nombre_modulo: 'Publisher',
        },
        {
          nombre_modulo: 'Power Point',
        },
      ],
      modalidad: 'Online',
      duracion: '4 meses',
      inversion_mensual: '225 Bs',
    },
    {
      nombre_curso: 'Diseño gráfico publicitario',
      img_card: 'img_disenio.png',
      modulos: [
        {
          nombre_modulo: 'Adobe Photoshop',
        },
        {
          nombre_modulo: 'Adobe Ilustrator',
        },
        {
          nombre_modulo: 'Adobe Indesing',
        },
      ],
      modalidad: 'Online',
      duracion: '3 meses',
      inversion_mensual: '275 Bs',
    },
    {
      nombre_curso: 'Mantenimiento y reparación de PC',
      img_card: 'img_soporte.png',
      modulos: [
        {
          nombre_modulo: 'Mantenimiento y reparación de PC',
        },
        {
          nombre_modulo: 'Configuración de redes',
        },
        {
          nombre_modulo: 'Electrónica',
        },
      ],
      modalidad: 'Semipresencial',
      duracion: '3 meses',
      inversion_mensual: '300 Bs',
    },
  ];
  personaTallerFormGroup: FormGroup;
  @ViewChild ('fform') PersonaTallerGratuitoFormDirective;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private personas_talleres_gratuitosService: PersonasTalleresGratuitosService,
    // private location: Location,
    private snackBar: MatSnackBar
  ) {
    this.buildFormTalleres();
  }

  buildFormTalleres() {
    this.personaTallerFormGroup = this.fb.group({
      nombre: [, [Validators.required]],
      celular: [, [Validators.required]],
      correo: [, [Validators.required, Validators.email]],
    });
    this.personaTallerFormGroup.valueChanges.subscribe((data) =>
      this.validar(data)
    );
  }

  ngOnInit(): void {}

  enviarMensajeWhatsapp() {
    let celular = '71864008';
    let url =
      'https://api.whatsapp.com/send?phone=591' +
      celular +
      ' &text=Me interesa conocer más sobre ...';
    window.open(url, '_blank');
  }
  onSubmit() {
    if(this.personaTallerFormGroup.valid){
      let valueForm = this.personaTallerFormGroup.value;
      console.log(valueForm);
      let persona_taller_gratuito = {} as PersonaTallerGratuito;
  
      persona_taller_gratuito.nombre = valueForm.nombre;
      persona_taller_gratuito.celular = valueForm.celular;
      persona_taller_gratuito.correo = valueForm.correo;
  
      this.personas_talleres_gratuitosService
        .insertPersonaTallerGratuito(persona_taller_gratuito)
        .subscribe(
          (res) => {
            console.log(res);
            this.snackBar.open('Guardado', null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            });
            this.PersonaTallerGratuitoFormDirective.resetForm();
         this.personaTallerFormGroup.reset({
          nombre: '',
          celular: '',
          correo:''
         });
          },
          (err) => {
            console.log(err);
            this.snackBar.open('Ocurrio un problema, no se guardo el registro', null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            });
          }
        );
    }
   
  }

  changeFocus(hash:string) {
    window.location.hash = hash;
}


  validar(data?: any) {
    if (!this.personaTallerFormGroup) {
      return;
    }
    const form = this.personaTallerFormGroup;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
  formErrors = {
    nombre: '',
    celular: '',
    correo: '',
  };
  validationMessages = {
    nombre: {
      required: 'Este campo es requerido',
    },
    celular: {
      required: 'Este campo es requerido',
    },
    correo: {
      required: 'Este campo es requerido',
      email: 'El correo electrónico es invalido',
    },
  };


}
